.search-suggestion-container {
    [data-user-value] {
        @apply font-medium;
        @apply text-grey-12;
    }

    [data-suggested-value] {
        @apply font-normal;
        @apply text-grey-12;
    }

    [data-reach-combobox-option-text] {
        @apply text-14;
        @apply truncate;
    }

    &[data-reach-combobox-option], &[aria-selected="true"] {
        @apply bg-transparent;
    }

    &[data-highlighted] {
        @apply bg-grey-4;
    }

    &[data-reach-combobox-option]:hover, &[aria-selected="true"]:hover {
        @apply bg-grey-4;
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --app-height: 100vh;
}

@layer base {
  a {
    @apply text-blue-9;
  }

  b,
  strong {
    @apply font-semibold;
  }

  /**
   * Hides the search input clear button for search inputs
   */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}


@layer utilities {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }


    /* Hide scrollbar for IE, Edge, and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}

body {
  @apply bg-grey-1;
}

code {
  @apply inline-block;
  @apply bg-pigeon-50;
  @apply px-2;
  @apply rounded;
  @apply font-mono;
  @apply font-medium;
  @apply text-pigeon-600;
  font-size: inherit;
}

* {
  min-width: 0;
  box-sizing: border-box;
}

.versions-list-container {
  &::-webkit-scrollbar {
    @apply h-1;
  }

  &::-webkit-scrollbar-track-piece {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-pigeon-200;
    @apply rounded-3xl;

    &:hover {
      @apply bg-pigeon-50;
    }
  }

  & > .version-list-item {
    @apply mr-4;

    &:first-of-type {
      @apply mr-8;
    }

    &:last-of-type {
      @apply mr-0 #{!important};
    }
  }
}

@keyframes new-shimmer {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.cypress {
  * {
    animation-duration: 0s !important;
    transition: none !important;
  }
}

/**
  * Explanation of why the below iOS specific stuff exists:
  * NOTE: this was found in iOS13
  * Safari triggers resize events on scroll and touch events.
  * We use a resize listener on the container which would get marked as sticky.
  * This leads to the container's height constantly being recalculated.
  * This means there are staggered and brief moments of height being marked as 0.
  * Those moments may be left unrectified by a misqueueing of the calculations until another event fixes it.
  * This leads to the disappearance of the element on touch and the flickering of it on scroll.

  * 1) The the GPU acceleration prevents the flickering on scroll for fixed/sticky elements.
  * 2) The switch to fixed ensures we dont care about the recalculating container for touch events on this element or it's children.
*/
@supports (-webkit-touch-callout: none) {
  .ios-safari-fixed-element-hack {
    position: fixed;
    width: 100vw;
  }
}

.ios-safari-fixed-element-hack {
  -webkit-transform: translate3d(0, 0, 0);
}

label.auto-width {
  @apply min-w-0 inline-grid align-top items-center relative box-border m-0.5 overflow-hidden;

  &::after {
    @apply text-grey-11 box-border invisible whitespace-pre-wrap w-auto flex pointer-events-none font-medium border-none rounded resize-none bg-none appearance-none;
    content: attr(data-value) " ";
    grid-area: 1 / 2;

    &:focus-within {
      input {
        outline: none;
      }
    }
  }

  & > input {
    @apply min-w-0 flex w-auto resize-none appearance-none box-border;
    grid-area: 1 / 2;
  }
}
